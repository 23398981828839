(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/flags/assets/javascripts/flags.js') >= 0) return;  svs.modules.push('/components/lb-ui/flags/assets/javascripts/flags.js');
"use strict";


(function (svs, React) {
  'use strict';

  svs = svs || {};
  svs.lb_ui = svs.lb_ui || {};
  class Flag extends React.Component {
    render() {
      const iso = this.props.iso.toLowerCase();
      const title = this.props.title ? this.props.title : svs.lb_ui.flags.getFlag(iso, 'name');
      const imgSrc = svs.lb_ui.flags.getFlag(iso);
      let className = 'flag';
      className += this.props.class ? ' ' + this.props.class : '';
      className += this.props.size ? ' flag-' + this.props.size : '';
      className += ' flag-' + this.props.iso;
      return React.createElement("span", {
        className: className,
        title: title,
        "data-iso": iso
      }, React.createElement("img", {
        alt: this.props.alt,
        className: "flag-svg",
        src: imgSrc
      }));
    }
  }
  Flag.defaultProps = {
    alt: '',
    className: 'flag flag-default'
  };
  svs.lb_ui.ReactFlag = Flag;
})(svs, React);

 })(window);